<template>
  <div class="flex md12">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">

    <div v-if="!device" style="margin: 0 auto;">
      <div class="row" style="justify-content: center;">

        <div :style="topScaleRatio">
          <div class="company_logo"><img src="@/assets/infyn.png" width="130" height="130" style="margin-left: 5rem !important;" /></div>
          <div class="left_screen_background">
            <img class="image1" src="@/assets/tv.png" />
            <video
              v-if="loaderHide"
              class="pageLoader"
              src="@/assets/FinalVideoLoader.mp4"
              autoplay 
              loop
              muted
              playsinline
            />
            <div class="transparentImage"></div>
            <video
              class="image2"
              :src="homeVideoPath"
              autoplay 
              loop
              muted
              playsinline
            />
            <img class="menuLogo" src="@/assets/menu.png" />
            <img class="tvInCompnayLogo" :src="brandLogo" />
            <img class="tvInBrandLogo" src="@/assets/powered_by_infynect.png" />
            <span class="guest_name"><b>{{guest_name}}</b></span>
            <span class="SWelcomeMsg">{{welcomeMsg}}</span>
            <img class="kitchen_label" src="@/assets/label.png" />
            <img class="kitchenLogo" src="@/assets/cooking.png" />
            <img class="timeLogo" src="@/assets/time.png" />
            <span class="timetextTag">Time</span>
            <span class="timeTag">{{on_time}}</span>
            <span class="kitchentextTag">Kitchen Closes</span>
            <span class="kitchentimeTag">{{kitchen_close}}</span>
            <img class="lineset" src="@/assets/line.png" />
            <span class="labelTag">Service</span>
            <div class="sfoodlisttag">
              <div>
                <a v-for="service in serviceLists">
                  <img class="imageTile ml-2" :src="service.image_path" />
                </a>
              </div>
            </div>
            <span class="locationLabel">Apps</span>
            <div class="slocationlisttag">
              <div>
                <a>
                  <img class="imageTile ml-2" src="@/assets/zee5.png" />
                  <img class="imageTile ml-2" src="@/assets/sonyliv.png" />
                  <img class="imageTile ml-2" src="@/assets/voot.png" />
                  <img class="imageTile ml-2" src="@/assets/erosnow.png" />
                </a>
              </div>
            </div>
            <span class="brandText">Create an immersive in-room experience with Hepi</span>
            <span class="brandText2">make your guest feel extra special</span>

            <img src="@/assets/bottomMenuLine.png" class="bh" />
            <img src="@/assets/bh2.png" class="bh1" @click="redirect('branding')" style="cursor: pointer;" />
            <img src="@/assets/fh2.png" class="bh2" @click="redirect('food')" style="cursor: pointer;" />
            <img src="@/assets/lh2.png" class="bh3" @click="redirect('location')" style="cursor: pointer;" />
            <img src="@/assets/sh1.png" class="bh4" />
            <img src="@/assets/ih2.png" class="bh5" @click="redirect('integration')" style="cursor: pointer;"/>
            <span class="bt1">Branding</span>
            <span class="bt2">Food Menu</span>
            <span class="bt3">Nearby Places</span>
            <span class="bt4">Services</span>
            <span class="bt5">Integration</span>
          </div>
        </div>

        <div :style="bottomScaleRatio">
          <div class="cardView">
            <img class="fcard_header" src="@/assets/card_rectangle.png"/>
            <center>
              <span class="fcardheaderline">{{location_header}}</span>
            </center>
            <img class="topleftCard" src="@/assets/cardtopleft.png" />
            <img class="bottomrightCard" src="@/assets/cardbottomright.png" />
            <div class="insideCard">
              <div v-for="service in serviceLists">
                <div class="mt-2">
                  <div v-if="!service.show" @click="showData(service, true)" style="cursor: pointer;">
                    <img class="Scardlistimage" :src="service.image_path" />
                    <span class="Scardlistname">{{service.name}}</span>
                    <img 
                      src="@/assets/Arrow_down.png" 
                      class="sdropdownTag"  
                    />
                  </div>
                  <div v-else>
                    <va-card class="datacard">
                      <img class="insideTransparent" src="@/assets/2nd_option.png" />
                      <div @click="showData(service, false)" style="cursor: pointer;">
                        <img class="Scard_data_image" :src="service.image_path" />
                        <span class="Scardlistname" style="margin-left: -1px;">{{service.name}}</span>
                        <img src="@/assets/Arrow_up.png" class="dropUpTag" />
                      </div>
                      <div>
                        <div class="mt-3">
                          <img class="ser_videobuttonl1" src="@/assets/belowLabel.png" />
                          <label class="ser_videobuttonf2">Service Logo</label>
                          <button class="flogobuttonlistner" @click="onClickHandler('image', service)">Upload Logo</button>
                        </div>
                        <div class="mt-3">
                          <img class="ser_foodName1" src="@/assets/belowLabel.png" />
                          <label class="ser_dishName">Service Name</label>
                          <input class="Sinput_tag" v-model="service.name" @focusout="serviceCustomize('name', service)" />
                        </div>
                      </div>
                    </va-card>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="margin-top: 45px; margin-left: 58px; margin-bottom: 50px;">
            <button class="bnext" @click="redirect('location')"><img src="@/assets/leftarrow.png" height="12" style="position: absolute; margin-left: -20px; margin-top: 4px;" />Nearby Places</button>
            <button class="bback ml-3" @click="redirect('integration')">Integration<img src="@/assets/rightarrow.png" height="12" style="position: absolute; margin-left: 6px; margin-top: 4px;" /></button>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import vueResource from 'vue-resource';
  import config from '../../i18n/en.json';
  import ScreenSizeDetector from 'screen-size-detector';
  Vue.use(vueResource)

  export default {
    created () {
      var screenSize=new ScreenSizeDetector().width;
      var scaleRatioNumber=screenSize<600 ? (0.00125*screenSize) : 1;
      this.topScaleRatio="zoom: "+scaleRatioNumber+";";
      this.bottomScaleRatio="zoom: "+(scaleRatioNumber!=1 ? scaleRatioNumber+0.2 : scaleRatioNumber)+";";
      const myTimer = () => {
        var date = new Date(), 
            noon = (date.getHours() >= 12) ? 'PM' : 'AM',
            hour = ((date.getHours()) > 12) ? ('0'+(date.getHours() - 12)) : date.getHours(),
            mins = ((date.getMinutes()) < 10) ? ('0'+(date.getMinutes())) : date.getMinutes();
        this.on_time = hour + ":" + mins + " " + noon;
      }
      setInterval(myTimer, 1000);
      setTimeout(() => { this.loader = false }, 2000);
      this.getUsers();
      this.getBrandings();
      this.getContents();
    },
    data () {
      return {
        welcomeMsg: '',
        locationLists: [],
        serviceLists: [],
        kitchen_close: '',
        on_time: '',
        location_header: 'Customize and display all the service of your hotel.',
        loader: true,
        device: false,
        guest_name: '',
        homeVideoPath: '',
        brandLogo: '',
        loaderHide: true,
        topScaleRatio: '',
        bottomScaleRatio: ''
      }
    },
    methods: {
      redirect(type) {
        this.$router.push({ name: type });
      },
      getUsers() {
        this.$http.get(config.menu.host + 'content/user').then(user => {
          this.guest_name = 'HI '+user.body[0].full_name.toUpperCase();
        });
      },
      getBrandings() {
        this.$http.get(config.menu.host + 'content/branding').then(responseBrand => {
          this.welcomeMsg = responseBrand.body[0].intro_message;
          this.homeVideoPath = responseBrand.body[0].video_path;
          this.homeSetting_id = responseBrand.body[0].homesetting_id;
          this.kitchen_close = responseBrand.body[0].kitchen_close;
          this.brandLogo = responseBrand.body[0].logo_path; 
          setTimeout(() => {
            this.loaderHide = false;
          }, 2000);
        });
      },
      getContents() {
        this.$http.get(config.menu.host + 'content/service').then(response => {
          this.serviceLists = response.body.map((data, index) => {
            data.index = index + 1;
            data.show = false;
            return data;
          });
        });
      },
      showData(row, type) {
        if(!type){
          return row.show = type;
        }else{
          var vm = this, flag = false;
          this.serviceLists.map((data, i) => {
            data.show = false
            if(i+1 == vm.serviceLists.length) flag = true
          })
          if(flag) return row.show = type;
        }
      },
      onClickHandler(ev, data) {
        var el = window._protected_reference = document.createElement("INPUT");
        el.type = "file";
        el.addEventListener('change', () => { 
          if(ev == 'image'){
            var file_name = el.files[0].name.split('.').pop();
            if(file_name.toLowerCase() !== 'mp4'){
              var payload = new FormData();
              payload.append('image', el.files[0]);
              payload.append('id', data.service_id);
              this.$http.put(config.menu.host + 'content/service_image', payload).then(response => {
                this.getContents();
              });
            }else{
              return Vue.notify({text:'Wrong File Format uploaded.', type:'error'});
            }
          };
        });
        el.click();
      },
      serviceCustomize(type, data){
        if(type == 'name'){
          var payload = {};
          payload['name'] = data.name;
          payload['id'] = data.service_id;

          this.$http.put(config.menu.host + 'content/service_image', payload).then(response => {
            this.getContents();
          });
        }
      }
    }
  }
</script>
<style>
body {
  background-image: url("../../assets/new_repeat.jpg");
  background-position: left top;
  background-repeat: repeat;
  background-size: 10px;
}

button {
  cursor: pointer;
}

.sfoodlisttag::-webkit-scrollbar {
  display: none;
}

.insideCard::-webkit-scrollbar {
  display: none;
}

.company_logo {
  position: absolute;
}

.left_screen_background {
  background-image: url("../../assets/circlebg.png");
  position: relative;
  background-position: center 1px;
  background-repeat: no-repeat;
  background-size: 679px;
  text-align: center;
  height: 670px;
  width: 800px;
}

.parent {
  position: relative;
}

.pageLoader {
  position: absolute;
  z-index: 10;
  left: 77px;
  height: 358px;
  width: 668px;
  top: 133px;
}

.image1 {
  width: 746px;
  margin-top: -38px;
}

.image2 {
  position: absolute;
  top: 136px;
  left: 180px;
  width: 535px;
  height: 304px;
}

.transparentImage {
  position: absolute;
  top: 133px;
  left: 119px;
  background-image: url("../../assets/transparent11.png");
  width: 597px;
  height: 355px;
  z-index: 3;
}

.tvInCompnayLogo {
  position: absolute;
  top: 145px;
  left: 125px;
  z-index: 4;
  width: 55px;
  height: 55px;
}

.tvInBrandLogo {
  position: absolute;
  width: 50px;
  height: 47px;
  z-index: 4;
  left: 183px;
  top: 150px;
}

.menuLogo {
  position: absolute;
  top: -3px;
  left: 234px;
  z-index: 5;
  width: 323px;
}

.guest_name {
  position: absolute;
  top: 207px;
  left: 125px;
  z-index: 3;
  height: 75px;
  color: #d96516;
  text-size-adjust: 50%;
}

.SWelcomeMsg {
  position: absolute;
  top: 232px;
  left: 126px;
  z-index: 3;
  width: 192px;
  font-size: 12px;
  color: #cccccc;
  text-align: left;
  white-space: normal;
  overflow: hidden;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-size-adjust: 50%;
}

.kitchen_label {
  position: absolute;
  top: 263px;
  left: 121px;
  z-index: 7;
  width: 180px;
  height: 123px;
}

.kitchenLogo {
  position: absolute;
  top: 291px;
  left: 241px;
  z-index: 8;
  width: 21px;
}

.kitchentextTag {
  position: absolute;
  top: 315px;
  left: 225px;
  z-index: 8;
  font-size: 10px;
  color: #cccccc;
  text-size-adjust: 50%;
}

.kitchentimeTag {
  position: absolute;
  top: 328px;
  left: 232px;
  z-index: 9;
  font-size: 11px;
  color: #d96516;
  font-weight: 600;
  text-size-adjust: 50%;
}

.timeLogo {
  position: absolute;
  top: 292px;
  left: 156px;
  z-index: 8;
  width: 20px;
}

.lineset {
  position: absolute;
  top: 316px;
  left: 209px;
  height: 30px;
  z-index: 8;
}

.timetextTag {
  position: absolute;
  top: 315px;
  left: 156px;
  z-index: 8;
  font-size: 10px;
  color: #cccccc;
  text-size-adjust: 50%;
}

.timeTag {
  position: absolute;
  top: 328px;
  left: 146px;
  z-index: 9;
  font-size: 11px;
  color: #d96516;
  font-weight: 600;
  text-size-adjust: 50%;
}

.labelTag {
  position: absolute;
  top: 354px;
  left: 130px;
  z-index: 9;
  font-size: 12px;
  color: #ffffff;
  text-size-adjust: 50%;
}

.sfoodlisttag {
  position: absolute;
  top: 376px;
  left: 60px;
  z-index: 9;
  overflow-x: scroll;
  scrollbar-width: none;
  white-space: nowrap;
  width: 597px;
}

.imageTile {
  border-radius: 7px;
  width: 88px;
  height: 51px;
}

.locationLabel {
  position: absolute;
  top: 439px;
  left: 129px;
  z-index: 9;
  font-size: 12px;
  color: #ffffff;
  text-size-adjust: 50%;
}

.slocationlisttag {
  position: absolute;
  top: 461px;
  left: 12px;
  z-index: 9;
  overflow: hidden;
  scrollbar-width: none;
  white-space: nowrap;
  width: 597px;
  height: 30px;
}

.right_screen_background {
  position: fixed;
}

.insideTransparent {
  width: 339px;
  position: absolute;
  left: -1px;
  top: 0;
  height: 93px;
}

.cardView {
  position: relative;
  width: 370px;
  background-color: #343434;
  height: 507px;
  z-index: 1;
  margin-top: 135px;
}

.fcard_header {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 369px;
  height: 65px;
}

.fcardheaderline {
  position: absolute;
  top: 9px;
  left: 44px;
  width: 279px;
  font-size: 16px;
  color: white;
  text-size-adjust: 80%;
}

.insideCard {
  position: absolute;
  top: 79px;
  left: 15px;
  width: 355px;
  height: 419px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.topleftCard {
  position: absolute;
  top: -8px;
  left: -8px;
  width: 80px;
  height: 70px;
}

.bottomrightCard {
  position: absolute;
  top: 446px;
  left: 299px;
  width: 80px;
  height: 70px;
}

.Sinput_tag {
  background-color: #232222;
  border: 1px solid #3f3f3f;
  border-radius: 8px;
  height: 50px;
  width: 296px;
  text-indent: 19px;
  color: white;
  font-size: 13px;
  text-size-adjust: 50%;
}

.card_header {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 398px;
  height: 65px;
}

.cardheaderline {
  position: absolute;
  top: 9px;
  left: 60px;
  width: 279px;
  font-size: 16px;
  color: #cccccc;
  text-size-adjust: 50%;
}

.brandText {
  font-size: 23px;
  color: white;
  font-weight: 600;
  left: 139px;
  top: 548px;
  position: absolute;
  text-size-adjust: 50%;
}

.brandText2 {
  font-size: 23px;
  color: #8b8e94;
  left: 231px;
  top: 577px;
  position: absolute;
  text-size-adjust: 50%;
}

.bh {
  left: 150px;
  top: 671px;
  position: absolute;
  width: 487px;
}

.bh1 {
  left: 134px;
  top: 630px;
  position: absolute;
  height: 83px;
}

.bh2 {
  left: 245px;
  top: 631px;
  position: absolute;
  height: 83px;
}

.bh3 {
  left: 359px;
  top: 631px;
  position: absolute;
  height: 83px;
}

.bh4 {
  left: 477px;
  top: 631px;
  position: absolute;
  height: 83px;
}

.bh5 {
  left: 591px;
  top: 631px;
  position: absolute;
  height: 83px;
}

.bt1 {
  left: 153px;
  top: 716px;
  position: absolute;
  font-size: 12px;
  color: white;
  text-size-adjust: 50%;
}

.bt2 {
  left: 259px;
  top: 716px;
  position: absolute;
  font-size: 12px;
  color: white;
  text-size-adjust: 50%;
}

.bt3 {
  left: 367px;
  top: 716px;
  position: absolute;
  font-size: 12px;
  color: white;
  text-size-adjust: 50%;
}

.bt4 {
  left: 496px;
  top: 718px;
  position: absolute;
  font-size: 12px;
  color: white;
  text-size-adjust: 50%;
}

.bt5 {
  left: 607px;
  top: 718px;
  position: absolute;
  font-size: 12px;
  color: #343434;
  text-size-adjust: 50%;
}

.bback {
  padding: 8px 30px;
  font-size: 16px;
  border-radius: 4px;
  left: 1211px;
  top: 719px;
  background-color: #f8741a;
  border: #f8741a;
  color: white;
  text-size-adjust: 72%;
}

.bnext {
  padding: 8px 34px;
  font-size: 16px;
  color: white;
  border-radius: 4px;
  left: 1071px;
  top: 719px;
  background-color: #272727;
  border: #272727;
  text-size-adjust: 72%;
}

.Scardlistimage {
  height: 57px;
  border-radius: 11px;
  width: 84px;
  margin-left: 22px;
}

.Scard_data_image {
  height: 57px;
  border-radius: 11px;
  width: 84px;
  margin-left: 1px;
}

.Scardlistname {
  position: absolute;
  font-size: 17px;
  left: 118px;
  margin-top: 17px;
  color: white;
  width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-size-adjust: 50%;
}

.cardlistprice {
  position: absolute;
  color: white;
  margin-top: 25px;
  left: 119px;
}

.sdropdownTag {
  position: absolute;
  left: 305px;
  margin-top: 28px;
  cursor: pointer;
}

.dropUpTag {
  position: absolute;
  left: 295px;
  margin-top: 29px;
  cursor: pointer;
}

.datacard {
  width: 336px;
  height: 240px;
  margin-left: 4px;
  background-color: #232222;
  border-radius: 17px;
  overflow: hidden;
}

.ser_videobuttonl1 {
  position: absolute;
  width: 83px;
  margin-top: -8px;
  margin-left: 14px;
  color: #2d2c2c;
}

.ser_videobuttonf2 {
  position: absolute;
  margin-top: -11px;
  margin-left: 17px;
  color: #676767;
  font-size: 14px;
  text-size-adjust: 82%;
}

.ser_foodName1 {
  position: absolute;
  height: 22px;
  margin-top: -11px;
  margin-left: 14px;
  width: 90px;
}

.ser_dishName {
  position: absolute;
  margin-top: -10px;
  margin-left: 17px;
  color: #676767;
  font-size: 14px;
  text-size-adjust: 82%;
}

.flogobuttonlistner {
  width: 300px;
  height: 50px;
  border-radius: 8px;
  background-color: #232222;
  border: 1px solid #3f3f3f;
  color: #343434;
}

.loader {
  position: absolute;
  height: 365px;
  width: 632px;
  z-index: 10;
  left: 138px;
  top: 164px;
}
</style>
